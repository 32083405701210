import axios from "axios";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import sayingNoImage from "../../assets/sayingNo1.png";
import { TfiLock } from "react-icons/tfi";
import ReactPaginate from "react-paginate";
import "./riddles.css";
import { useNavigate } from "react-router-dom";

export const Riddles = ({setIsAuthenticated}) => {
	const navigate = useNavigate();
	const [riddles, setRiddles] = useState([]);
	const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
	const [activeOverlayIndex, setActiveOverlayIndex] = useState(
		Array(42).fill(null)
	);

	const [showHints, setShowHints] = useState(Array(42).fill(false));
	const [hintMessages, setHintMessages] = useState(Array(42).fill(""));

	const userLevel = parseInt(localStorage.getItem("userLevel")) || 1;

	const itemsPerPage = 8;
	const initialPage = Math.floor((userLevel - 1) / itemsPerPage);

	const items = Array.from({ length: 42 }, (_, index) => {
		if (index < riddles.length && riddles[index].level <= userLevel) {
			return { isRiddle: true, riddle: riddles[index] };
		}
		return { isRiddle: false };
	});

	const [currentPage, setCurrentPage] = useState(initialPage);

	const inputRefs = useRef(Array(42).fill(null));

	const modalRef = useRef(null);

	const Hint = ({ index, pageIndex, showHint, onShowHint, hintMessage }) => (
		<div key={index} className="hint-container">
			<div key={index} className="hintIcon-container">
				<HiOutlineLightBulb
					className="hintIcon"
					key={index}
					onClick={() => onShowHint(index, pageIndex)} // Pass both index and pageIndex
				/>
			</div>
			<div className="hint-content">
				{showHint && (
					<p className={`hintText ${showHint ? "show" : "hide"}`}>
						{hintMessage}
					</p>
				)}
				{showHint && hintMessage === "No hint" ? (
					<>
						<img
							src={sayingNoImage}
							alt="No hint available"
							className="no-hint-image"
						/>
					</>
				) : (
					""
				)}
			</div>
		</div>
	);

	const handleShowHint = (index, pageIndex) => {
		const globalIndex = pageIndex * itemsPerPage + index; // Calculate the correct index across pages
		setShowHints((prevHints) =>
			prevHints.map((hint, i) => (i === globalIndex ? !hint : hint))
		);
		setHintMessages((prevMessages) => {
			const newMessages = [...prevMessages];
			newMessages[globalIndex] = riddles[globalIndex]?.hint || "No hint";
			return newMessages;
		});
	};

	useEffect(() => {
    const fetchRiddles = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found");
            return;
        }

        try {
            const response = await axios.post(
                "https://advancedmathriddles.com/backend/includes/process/p-get-riddles.php",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status < 200 || response.status >= 300) {
                throw new Error("Failed to fetch riddles");
            } else if (response.data.authorized === false) {
                localStorage.clear();
                setIsAuthenticated(false);
                navigate("/");
                return;
            } else {
                const { riddles } = response.data;
                const updatedRiddles = riddles.map((riddle) => ({
                    ...riddle,
                    image_url: riddle.image_url,
                }));
                setRiddles(updatedRiddles);
            }
        } catch (error) {
            console.error("Error fetching riddles:", error.message);
        }
    };

    fetchRiddles();
}, [userLevel]);

	useEffect(() => {
		const initialActiveIndexes = Array(42).fill(null);
		for (let i = 0; i < userLevel - 1; i++) {
			initialActiveIndexes[i] = i;
		}
		setActiveOverlayIndex(initialActiveIndexes);
	}, [userLevel]);

	const playSound = (soundFile) => {
		const audio = new Audio(`/sounds/${soundFile}`);
		audio.play();
	};

	const checkAnswer = useCallback(
		async (index, level) => {
			const userAnswer = inputRefs.current[index].value;
			const token = localStorage.getItem("token"); // Retrieve the token from localStorage
			if (!token) {
				console.error("No token found");
				return;
			}

			const sendData = {
				userAnswer: userAnswer,
				riddleLevel: parseInt(level),
			};

			try {
				const response = await axios.post(
					"https://advancedmathriddles.com/backend/includes/process/p-check-answer.php",
					sendData,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				if (response.status === 200) {
					if (response.data.authorized === false) {
						localStorage.clear();
						setIsAuthenticated(false);
						navigate("/");
						return;
					} else if (response.data.enteredAnswer === true) {
						inputRefs.current[index].value = "";
						setIsCorrectAnswer(true);
						playSound("correct-answer.mp3");

						if (parseInt(userLevel) === parseInt(level)) {
							const newLevel = parseInt(level) + 1;
							localStorage.setItem("userLevel", newLevel);
							const newPage = Math.floor((newLevel - 1) / itemsPerPage);

							// Set current page to new page if it changes
							if (newPage !== currentPage) {
								setCurrentPage(newPage);
							}
						}
					} else {
						setIsCorrectAnswer(false);
						playSound("wrong-answer.mp3");
					}
				}
			} catch (error) {
				console.error("Error during check answer:", error);
			}
		},
		[userLevel]
	);

	const closeModal = () => {
		setIsCorrectAnswer(false);
	};

	useEffect(() => {
		if (isCorrectAnswer && modalRef.current) {
			modalRef.current.focus();
		}
	}, [isCorrectAnswer]);

	const handleInputChange = (index, e) => {
		e.preventDefault();
		const value = e.target.value.replace(/[^0-9./-]/g, ""); // Filter the input
		inputRefs.current[index].value = value; // Update the value in the input
	};

	const handleOverlayClick = (index) => {
		setActiveOverlayIndex((prevIndexes) => {
			const newIndexes = [...prevIndexes];
			newIndexes[index] = newIndexes[index] === index ? null : index;
			return newIndexes;
		});
	};

	// Prepare the 42 items, mixing riddles and locks

	function Items({ currentItems }) {
		return (
			<>
				{currentItems.map((item, index) =>
					item.isRiddle ? (
						<div
							key={index}
							onClick={() =>
								item.riddle.level < userLevel &&
								activeOverlayIndex[index] === index &&
								handleOverlayClick(index)
							}
							className={
								item.riddle.level < userLevel &&
								activeOverlayIndex[index] === index
									? "overlay"
									: "riddle"
							}
						>
							{item.riddle.level < userLevel &&
							activeOverlayIndex[index] === index ? (
								<p key={index}>{item.riddle.level}</p>
							) : (
								<>
									<Hint
										key={index}
										index={index}
										pageIndex={currentPage}
										showHint={showHints[currentPage * itemsPerPage + index]}
										onShowHint={handleShowHint}
										hintMessage={
											hintMessages[currentPage * itemsPerPage + index]
										}
									/>

									<img
										id="riddle-image"
										alt="Riddle"
										className="riddle-image"
										src={item.riddle.image_url} // Display the fetched image or fallback
										onClick={(e) => {
											e.stopPropagation();
											handleOverlayClick(index);
										}}
									/>

									<input
										id={`answer-${index}`}
										name={`answer-${index}`}
										type="text"
										ref={(el) => (inputRefs.current[index] = el)}
										onChange={(e) => handleInputChange(index, e)}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												e.preventDefault();
												if (inputRefs.current[index].value.length !== 0) {
													checkAnswer(index, item.riddle.level);
												}
											}
										}}
									/>
									<FaLongArrowAltRight
										className="arrow-icon"
										onClick={() =>
											inputRefs.current[index].value.length !== 0 &&
											checkAnswer(index, item.riddle.level)
										}
									/>
								</>
							)}
						</div>
					) : (
						<div key={index} className="lock-container">
							<div className="lock-background">
								<TfiLock className="lock" />
							</div>
						</div>
					)
				)}
			</>
		);
	}

	function PaginatedItems({ items, itemsPerPage }) {
		const handlePageClick = useCallback(({ selected }) => {
			setCurrentPage(selected);
		}, []);

		const offset = currentPage * itemsPerPage;
		const currentItems = items.slice(offset, offset + itemsPerPage);
		const pageCount = Math.ceil(items.length / itemsPerPage);

		return (
			<>
				<Items currentItems={currentItems} />
				<ReactPaginate
					breakLabel="..."
					nextLabel={<FaLongArrowAltRight />}
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					previousLabel={<FaLongArrowAltLeft />}
					renderOnZeroPageCount={null}
					pageClassName="pagination-item" // Add this
					activeClassName="active"
					initialPage={currentPage}
				/>
			</>
		);
	}

	return (
		<div className="riddles">
			{isCorrectAnswer && (
				<div
					className={`modal visible `}
					onClick={closeModal}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
							closeModal();
						}
					}}
					tabIndex="0"
					ref={modalRef}
				>
					<div className="modal-content">
						<p>Correct Answer!</p>
					</div>
				</div>
			)}
			<div className="paginationContainer">
				<PaginatedItems
					items={items}
					itemsPerPage={8}
					initialPage={initialPage}
				/>
			</div>
		</div>
	);
};
