import React from 'react';
import './footer.css';

export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
      <p>Contact Us: <a href="mailto:info@advancedmathriddles.com">info@advancedmathriddles.com</a></p>
        <p>&copy; 2024 Advanced Math Riddles</p>
      </div>
    </footer>
  );
};
