/* global grecaptcha */
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./signupLogin.css";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "white",
	width: "30px",
	height: "30px",
};

export const SignupLogin = ({ setIsAuthenticated }) => {
	const navigate = useNavigate();

	const [chkChecked, setChkChecked] = useState(false);

	const handleCheckboxChange = () => {
		!signupErrors && setChkChecked(!chkChecked);
	};

	// for signup
	const [signupData, setSignupData] = useState({
		username: "",
		email: "",
		password: "",
		confirmPassword: "",
	});

	// for login
	const [loginData, setLoginData] = useState({
		emailUsername: "",
		password: "",
	});

	// Error messages state
	const [signupErrors, setSignupErrors] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [loginErrors, setLoginErrors] = useState("");
	const [loading, setLoading] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	// for signup
	const handleSignupChange = (e) => {
		setSignupData({ ...signupData, [e.target.name]: e.target.value });
	};

	// for signup
	const handleSignupSubmit = async (e) => {
		e.preventDefault();
		setDisableButton(true);
		setLoading(true);

		let recaptchaToken = "";

		try {
			// Wait for reCAPTCHA to generate a token
			recaptchaToken = await grecaptcha.enterprise.execute(
				"6LfFUUQqAAAAAHoTm8JGVjRHvLdr9S55wHmlfIMa",
				{ action: "signup" } // Changed action to 'signup' to match your backend
			);

			const sendData = {
				username: signupData.username,
				email: signupData.email,
				password: signupData.password,
				confirmPassword: signupData.confirmPassword,
				recaptchaToken: recaptchaToken, // Include the reCAPTCHA token
			};

			// Send signup request
			const response = await axios.post(
				"https://advancedmathriddles.com/backend/includes/process/p-signup.php",
				sendData
			);

			if (response.status === 200 && response.data.success) {
				setLoading(false);
				setSuccessMsg(response.data.success);
			} else {
				const msg = response.data.validateMsg;
				setLoading(false);
				setSignupErrors(msg);
			}
		} catch (error) {
			console.error("Error during signup:", error);
			setLoading(false);
			setSignupErrors("Signup process failed. Please try again.");
		}

		setDisableButton(false);
	};

	// for login
	const handleLoginChange = (e) => {
		setLoginData({ ...loginData, [e.target.name]: e.target.value });
	};

	// for login
	const handleLoginSubmit = async (e) => {
		e.preventDefault();
		const sendData = {
			emailUsername: loginData.emailUsername,
			password: loginData.password,
		};

		try {
			const response = await axios.post(
				"https://advancedmathriddles.com/backend/includes/process/p-login.php",
				sendData
			);

			if (
				response.status === 200 &&
				response.data.username &&
				response.data.level &&
				response.data.authorized &&
				response.data.token
			) {
				const username = response.data.username;
				const level = response.data.level;
				const token = response.data.token;

				if (response.data.authorized) {
					localStorage.setItem("username", username);
					localStorage.setItem("userLevel", level);
					localStorage.setItem("token", token);
					setIsAuthenticated(response.data.authorized);
					navigate("/dashboard");
				}
			} else {
				const msg = response.data.validateMsg;
				setLoginErrors(msg);
			}
		} catch (error) {
			console.error("Error during login:", error);
		}
	};

	return (
		<div
			id="signupLogin"
			className="signupLoginContainer"
			onClick={() => setSuccessMsg("")}
		>
			<p onClick={() => navigate("/")} className="title">
				Advanced Math Riddles
			</p>

			<div className="main">
				<input
					type="checkbox"
					name=""
					id="chk"
					aria-hidden="true"
					checked={chkChecked}
					onChange={handleCheckboxChange}
				/>
				<div className="signup">
					<form onSubmit={handleSignupSubmit}>
						<label
							aria-hidden="true"
							onClick={chkChecked ? handleCheckboxChange : undefined}
							style={{
								cursor: !chkChecked ? "default" : "pointer",
							}}
						>
							Sign up
						</label>
						<input
							onFocus={() => setSignupErrors("")}
							type="text"
							placeholder="Username"
							name="username"
							value={signupData.username}
							onChange={handleSignupChange}
						/>
						<input
							onFocus={() => setSignupErrors("")}
							placeholder="Email"
							name="email"
							value={signupData.email}
							onChange={handleSignupChange}
						/>
						<input
							onFocus={() => setSignupErrors("")}
							type="password"
							placeholder="Password"
							name="password"
							value={signupData.password}
							onChange={handleSignupChange}
						/>
						<input
							onFocus={() => setSignupErrors("")}
							type="password"
							name="confirmPassword"
							placeholder="Confirm Password"
							value={signupData.confirmPassword}
							onChange={handleSignupChange}
						/>

						<button className="btnSignup" disabled={disableButton}>
							Sign up
						</button>
					</form>
					{loading && (
						<ClipLoader
							className="spinner"
							loading={loading}
							cssOverride={override}
							size={150}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					)}
					<div className="signupLoginError">{signupErrors}</div>
					<div className="signupLoginSuccess">{successMsg}</div>
				</div>
				<div
					className="login"
					style={
						signupErrors || successMsg || loading
							? { transform: "translateY(100%)" }
							: {}
					}
				>
					<form onSubmit={handleLoginSubmit}>
						<label
							aria-hidden="true"
							onClick={!chkChecked ? handleCheckboxChange : undefined}
							style={{
								cursor: !chkChecked ? "pointer" : "default",
							}}
						>
							Login
						</label>
						<input
							type="text"
							onFocus={() => setLoginErrors("")}
							placeholder="Email / Username"
							name="emailUsername"
							value={loginData.emailUsername}
							onChange={handleLoginChange}
						/>
						<input
							onFocus={() => setLoginErrors("")}
							type="password"
							placeholder="Password"
							name="password"
							value={loginData.password}
							onChange={handleLoginChange}
						/>
						<button className="btnLogin">Login</button>
					</form>
					<div className="error">{loginErrors}</div>
					<Link className="forgotPasswordLink" to={"/forgotPassword"}>
						Forgot password?
					</Link>
				</div>
			</div>
		</div>
	);
};
