import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import './activateAccount.css';

export const ActivateAccount = () => {
  const [error, setError] = useState('');
  const [isActivated, setIsActivated] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    const activateAccount = async () => {
      const sendData = {
        token: token.split("=")[1],
      };

      try {
        const response = await axios.post(
          "https://advancedmathriddles.com/backend/includes/process/p-activate-account.php",
          sendData
        );

        if (response.data.tokenError) {
          const msg = response.data.tokenError;
          setError(msg);
        } else {
          setIsActivated(true); // Set this flag if activation is successful
        }
      } catch (error) {
        console.error("Error during account activation:", error);
      }
    };

    activateAccount(); // Call the function inside useEffect
  }, []); // Empty dependency array means this useEffect will run once when the component mounts

  return (
    <div className='activateAccount'>
      {/* Your component content here */}
      {error && !isActivated && <p className='error'>{error}</p>}
      {isActivated && <p>Account activated successfully! You can now <Link to='/signupLogin' className='loginLink'>Login</Link></p>}
    </div>
  );
};
