import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdReorder } from "react-icons/md";
import { Riddles } from "../../components/riddles/Riddles";
import { Footer } from "../../components/Footer/Footer";
import axios from "axios";
import "./dashboard.css";

export const Dashboard = ({ setIsAuthenticated }) => {
	const navigate = useNavigate();
	const [expandNavbar, setExpandNavbar] = useState(false);
	const [showOverlay, setShowOverlay] = useState(false);
	const [loading, setLoading] = useState(false);
	const [topPlayers, setTopPlayers] = useState([]);
	const username = localStorage.getItem("username");
	const token = localStorage.getItem("token");

	const overlayRef = useRef(null);
	const topPlayersRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				overlayRef.current &&
				!overlayRef.current.contains(event.target) &&
				!topPlayersRef.current.contains(event.target)
			) {
				setShowOverlay(false);
			}
		};

		if (showOverlay) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showOverlay]);

	// Function to handle logout
	const handleLogout = async () => {
		localStorage.clear();
		setIsAuthenticated(false);
		navigate("/");
	};

	const fetchTopPlayers = async () => {
		if (showOverlay) {
			setShowOverlay(false);
			return;
		}
		setLoading(true);
		setShowOverlay(true);

		const token = localStorage.getItem("token"); // Retrieve the token from localStorage

		if (!token) {
			console.error("No token found");
			setLoading(false);
			setShowOverlay(false);
			return;
		}

		try {
			const response = await axios.post(
				"https://advancedmathriddles.com/backend/includes/process/p-get-top-players.php",
				{}, // No body needed for this request
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200 && response.data.authorized === false) {
				// Token is invalid, log out the user
				localStorage.clear();
				setIsAuthenticated(false);
				navigate("/");
			} else if (response.status === 200 && response.data.topPlayers) {
				const { topPlayers } = response.data;
				setTopPlayers(topPlayers);
			} else {
				throw new Error("Failed to fetch top players");
			}
		} catch (error) {
			console.error("Error fetching top players:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="dashboard">
			<nav className="navbar" id={expandNavbar ? "open" : undefined}>
				<div className="container">
					<p className="username">{username}</p>
					<div className="toggleButtonLogin">
						<button
							onClick={() => {
								setExpandNavbar((prev) => !prev);
							}}
						>
							<MdReorder />
						</button>
					</div>
					<ul className="main-nav">
						<li>
							<Link to="/manageAccount">Manage Account</Link>
						</li>
						<li>
							<Link onClick={fetchTopPlayers} ref={topPlayersRef}>
								Top Players
							</Link>
							{showOverlay && (
								<div
									className={`top-players-overlay ${loading ? "show" : ""}`}
									ref={overlayRef}
								>
									{loading ? (
										<div className="loader"></div>
									) : (
										<div className="topPlayersBox">
											<h1>Highest Level Players</h1>
											<ul>
												{topPlayers.map((player, index) => (
													<li
														key={index}
														className={index === 0 ? "crown" : ""}
													>
														{player.username} - {player.level}
													</li>
												))}
											</ul>
										</div>
									)}
								</div>
							)}
						</li>
						<li>
							<Link className="btnLogout" onClick={handleLogout}>
								Logout
							</Link>
						</li>
					</ul>
				</div>
			</nav>
			<div className="content">
				<Riddles setIsAuthenticated={setIsAuthenticated} />
			</div>
			<Footer />
		</div>
	);
};
